import React from 'react';
import HeroSection from '../../components/HeroSection/HeroSection';
import HeroImage from '../../assets/images/hero.webp';
import { useNavigate } from 'react-router-dom';
import AchievementBar from '../../components/AchievementBar/AchievementBar';
import StyledWrapper from '../../components/StyledWrapper/StyledWrapper';
import FeaturedService from '../../components/FeaturedService/FeaturedService';
import './Home.css';
import ABOUT_HOME_IMAGE from '../../assets/images/indiana.webp';
// import COMPANY_IMAGE_1 from '../../assets/images/company-logo.webp';
// import HEADSHOT_1 from '../../assets/images/headshot.webp';
// import FeaturedFeedback from '../../components/FeaturedFeedback/FeaturedFeedback';
import StyledButton from '../../components/StyledButton/StyledButton';
import { featuredServices } from '../Services/Services';
import { Helmet } from 'react-helmet';

// type FeaturedFeedbackItem = {
//   name: string;
//   description: string;
//   companyImage: string;
//   headshotImage: string;
//   jobTitle: string;
// };

const featuredServicesHome = featuredServices.slice(0, 3);

const Home: React.FC = () => {
  const navigate = useNavigate();

  // const featuredFeedback: FeaturedFeedbackItem[] = [
  //   {
  //     name: 'Korey Madden',
  //     description:
  //       'Cras tincidunt dapibus vulputate. Fusce sed justo mollis, viverra nulla quis, convallis diam. Quisque lacinia auctor felis in eleifend. Lacinia auctor felis in eleifend.',
  //     companyImage: COMPANY_IMAGE_1,
  //     headshotImage: HEADSHOT_1,
  //     jobTitle: 'CEO of Built Robotics',
  //   },
  //   {
  //     name: 'Korey Madden',
  //     description:
  //       'Cras tincidunt dapibus vulputate. Fusce sed justo mollis, viverra nulla quis, convallis diam. Quisque lacinia auctor felis in eleifend. Lacinia auctor felis in eleifend.',
  //     companyImage: COMPANY_IMAGE_1,
  //     headshotImage: HEADSHOT_1,
  //     jobTitle: 'CEO of Built Robotics',
  //   },
  //   {
  //     name: 'Korey Madden',
  //     description:
  //       'Cras tincidunt dapibus vulputate. Fusce sed justo mollis, viverra nulla quis, convallis diam. Quisque lacinia auctor felis in eleifend. Lacinia auctor felis in eleifend.',
  //     companyImage: COMPANY_IMAGE_1,
  //     headshotImage: HEADSHOT_1,
  //     jobTitle: 'CEO of Built Robotics',
  //   },
  // ];

  return (
    <div>
      <Helmet>
        <title>WildMane Engineering</title>
        <meta
          name="description"
          content="Discover top-notch software consulting services at WildMane Engineering, specializing in mobile, web, embedded, cloud applications, electrical engineering, and PCB design. Our expert team delivers innovative and reliable solutions to elevate your business. From custom mobile apps and responsive web development to cutting-edge embedded systems, cloud integration, and precision PCB engineering, we ensure excellence in every project. Partner with us for comprehensive, tailored technology solutions that drive success and efficiency. Contact WildMane Engineering today for unparalleled expertise in software consulting and engineering."
        />
        <meta
          name="keywords"
          content="software consulting, technology solutions, custom software development, innovative software, software experts, business technology services"
        />
        <meta name="robots" content="index, nofollow" />
      </Helmet>
      <HeroSection
        image={HeroImage}
        title="Experience a New Level of Success with Our Consultancy"
        description="Innovative Solutions, Trusted Partnerships for Lasting Success"
        buttonText="Contact Now"
        buttonClick={() => navigate('/contact')}
      />
      <AchievementBar />
      <StyledWrapper
        backgroundColor="#D6CEBD"
        titleLarge="Featured Services"
        titleSmall="Services"
        style={wrapperStyles1}
      >
        <div className="featured-services-home">
          {featuredServicesHome.map(({ title, description, image }, index) => (
            <FeaturedService
              key={title + index}
              title={title}
              description={description}
              image={image}
            />
          ))}
        </div>
        <StyledButton onClick={() => navigate('/services')} text="View All" />
      </StyledWrapper>
      <div className="about-home-wrapper">
        <div className="about-home-container">
          <div
            className="about-home-image"
            style={{ backgroundImage: `url(${ABOUT_HOME_IMAGE})` }}
          />
          <div className="about-home-text">
            <div className="about-home-title-small">Who We Are</div>
            <div className="about-home-title-large">WildMane Engineering</div>
            <div className="about-home-paragraph">
              We are a dedicated team of software consulting experts committed
              to delivering innovative and reliable solutions tailored to your
              unique needs. With a focus on customer satisfaction, we prioritize
              understanding your business goals and challenges to provide
              personalized, high-quality services. Our expertise spans mobile
              and cloud applications, embedded development, web applications,
              PCB design, and electrical engineering.
            </div>
            <div className="about-home-paragraph">
              We uphold the values of integrity, innovation, quality, and
              customer focus, ensuring transparent and ethical practices in all
              our interactions. Partner with us for cutting-edge technology
              solutions and a trusted relationship that drives your lasting
              success.
            </div>
          </div>
        </div>
      </div>
      {/* <StyledWrapper
        backgroundColor="#D6CEBD"
        titleLarge="What They've Said"
        titleSmall="Partners"
        horizontalPadding={false}
        style={wrapperStyles2}
      >
        <div className="featured-feedback-home">
          {featuredFeedback.map(
            (
              { companyImage, description, headshotImage, jobTitle, name },
              index,
            ) => (
              <FeaturedFeedback
                key={name + index}
                companyImage={companyImage}
                description={description}
                headshotImage={headshotImage}
                jobTitle={jobTitle}
                name={name}
              />
            ),
          )}
        </div>
      </StyledWrapper> */}
    </div>
  );
};

const wrapperStyles1: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 25,
  justifyContent: 'center',
  alignItems: 'center',
};

// const wrapperStyles2: React.CSSProperties = {
//   display: 'flex',
//   justifyContent: 'center',
//   width: '100%',
// };

export default Home;
