import { useState } from 'react';
import StyledButton from '../StyledButton/StyledButton';
import Logo from '../../assets/images/logo.svg';
import './MessageBox.css';
import Lottie, { Options } from 'react-lottie';
import * as animationData from '../../assets/images/sent.json';

type FormData = {
  firstName: string;
  lastName: string;
  phone: string;
  message: string;
  email: string;
};

const defaultOptions: Options = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const MessageBox = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [messageFailed, setMessageFailed] = useState(false);
  const [messageSending, setMessageSending] = useState(false);
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const handleNameChange = (value: string, type: 'first' | 'last') => {
    if (value.length < 2) {
      if (type === 'first') {
        setFirstNameError(true);
      } else if (type === 'last') {
        setLastNameError(true);
      }
    } else {
      if (type === 'first') {
        setFirstNameError(false);
      } else if (type === 'last') {
        setLastNameError(false);
      }
    }
    if (type === 'first') {
      setFirstName(value);
    } else if (type === 'last') {
      setLastName(value);
    }
  };

  const handleEmailChange = (value: string) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailPattern.test(value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmail(value);
  };

  const handleMessageChange = (value: string) => {
    if (value) {
      setMessageError(false);
    } else {
      setMessageError(true);
    }
    setMessage(value);
  };

  const handlePhoneChange = (value: string) => {
    const inputPhoneNumber = value.replace(/[^\d]/g, '');
    if (inputPhoneNumber.length === 10) {
      const formattedNumber = `(${inputPhoneNumber.slice(
        0,
        3,
      )}) ${inputPhoneNumber.slice(3, 6)}-${inputPhoneNumber.slice(6)}`;
      setPhone(formattedNumber);
      setPhoneError(false);
    } else if (inputPhoneNumber.length > 10) {
      return;
    } else {
      setPhone(inputPhoneNumber);
      setPhoneError(true);
    }
  };

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setMessage('');
    setMessageSent(false);
  };

  const retryForm = () => {
    setMessageFailed(false);
    setErrorCode(null);
    validateAndSubmit();
  };

  const postData = (formData: FormData) => {
    fetch('https://wildmane.dev/send-email.php', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        console.log('[RESPONSE]:', response);
        if (!response.ok) {
          setErrorCode(response.status.toString());
          throw new Error('Network response was NOT ok!');
        } else {
          setMessageSent(true);
        }
        return response.json();
      })
      .then((data) => {
        console.log('[DATA]:', data);
      })
      .catch((error) => {
        console.error('[ERROR]:', error);
        setMessageFailed(true);
      })
      .finally(() => {
        setMessageSending(false);
      });
  };

  const validateAndSubmit = () => {
    const phoneValid = phone.length >= 10;
    if (!phoneValid) {
      setPhoneError(true);
    }
    const firstNameValid = firstName.length >= 2;
    if (!firstNameValid) {
      setFirstNameError(true);
    }
    const lastNameValid = lastName.length >= 2;
    if (!lastNameValid) {
      setLastNameError(true);
    }
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const emailValid = emailPattern.test(email);
    if (!emailValid) {
      setEmailError(true);
    }
    const messageValid = message.length > 0;
    if (!phoneValid) {
      setMessageError(true);
    }
    if (
      phoneValid &&
      firstNameValid &&
      lastNameValid &&
      messageValid &&
      emailValid
    ) {
      setPhoneError(false);
      setFirstNameError(false);
      setLastNameError(false);
      setEmailError(false);
      setMessageError(false);
      setMessageSending(true);
      postData({
        email,
        message,
        firstName,
        lastName,
        phone,
      });
    }
  };

  const inputStyle: React.CSSProperties = {
    backgroundColor: messageSending ? '#d3d3d3' : undefined,
    color: messageSending ? '#a0a0a0' : undefined,
    cursor: messageSending ? 'not-allowed' : undefined,
  };

  return (
    <div className="message-box">
      <div className="message-box-main">
        <div className="message-box-left">
          {messageSent ? (
            <div className="message-sent">
              <Lottie style={lottieStyles} options={defaultOptions} />
              <div className="result-text">
                Your message has been delivered.
                <br />
                We'll be in touch shortly!
              </div>
              <StyledButton text="New Message" onClick={resetForm} />
            </div>
          ) : (
            <>
              <div className="input-row">
                <div className="input-group">
                  <div className="input-label">First Name</div>
                  <input
                    type="text"
                    autoComplete="given-name"
                    className="form-input"
                    style={inputStyle}
                    disabled={messageSending}
                    value={firstName}
                    onChange={(e) => handleNameChange(e.target.value, 'first')}
                  />
                  <div className="error-text">
                    {firstNameError && 'Should be at least 2 characters.'}
                  </div>
                </div>
                <div className="input-group">
                  <div className="input-label">Last Name</div>
                  <input
                    type="text"
                    autoComplete="family-name"
                    className="form-input"
                    style={inputStyle}
                    disabled={messageSending}
                    value={lastName}
                    onChange={(e) => handleNameChange(e.target.value, 'last')}
                  />
                  <div className="error-text">
                    {lastNameError && 'Should be at least 2 characters.'}
                  </div>
                </div>
              </div>
              <div className="input-row">
                <div className="input-group">
                  <div className="input-label">Email</div>
                  <input
                    type="email"
                    autoComplete="email"
                    className="form-input"
                    style={inputStyle}
                    disabled={messageSending}
                    value={email}
                    onChange={(e) => handleEmailChange(e.target.value)}
                  />
                  <div className="error-text">
                    {emailError && 'Your email address is invalid.'}
                  </div>
                </div>
                <div className="input-group">
                  <div className="input-label">Phone</div>
                  <input
                    type="tel"
                    autoComplete="tel"
                    className="form-input"
                    style={inputStyle}
                    disabled={messageSending}
                    value={phone}
                    onChange={(e) => handlePhoneChange(e.target.value)}
                  />
                  <div className="error-text">
                    {phoneError && 'Enter your 10-digit phone number.'}
                  </div>
                </div>
              </div>
              <div className="input-row">
                <div className="input-group">
                  <div className="input-label">Message</div>
                  <textarea
                    disabled={messageSending}
                    style={inputStyle}
                    className="form-area"
                    value={message}
                    onChange={(e) => handleMessageChange(e.target.value)}
                  />
                  <div className="error-text">
                    {messageError && 'A message is required.'}
                  </div>
                </div>
              </div>
              <div className="input-row">
                <StyledButton
                  text={
                    messageSending
                      ? 'Sending...'
                      : messageSent
                        ? 'New Message'
                        : messageFailed
                          ? 'Try Again'
                          : 'Submit'
                  }
                  onClick={
                    messageSending
                      ? () => null
                      : messageSent
                        ? resetForm
                        : messageFailed
                          ? retryForm
                          : validateAndSubmit
                  }
                  style={
                    messageSending
                      ? {
                          opacity: 0.5,
                          cursor: 'default',
                        }
                      : messageFailed
                        ? {
                            backgroundColor: '#68350a',
                            fontWeight: 600,
                            textTransform: 'uppercase',
                          }
                        : undefined
                  }
                />
              </div>
              {errorCode || messageFailed ? (
                <div className="input-row">
                  <div className="error-text" style={{ position: 'static' }}>
                    {errorCode && `Error Code: [${errorCode}]`}{' '}
                    {messageFailed && 'Failed to Deliver Message.'}
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
        <div className="message-box-right">
          <a
            className="right-item right-item-phone"
            type="tel"
            href="tel:+5743281323"
          >
            (574) 328-1323
          </a>
          <a
            className="right-item right-item-email"
            type="email"
            href="mailto:contact-us@wildmane.dev"
          >
            contact-us@wildmane.dev
          </a>
          {/* <a
            className="right-item right-item-address"
            href="https://www.google.com/maps/search/?api=1&query=1600+Amphitheatre+Parkway,+Mountain+View,+CA"
          >
            1600 Amphitheatre Parkway
            <br />
            Mountain View, CA
          </a> */}
          <img
            alt="WildMane Engineering Logo"
            src={Logo}
            className="right-item right-item-image"
          />
        </div>
      </div>
    </div>
  );
};

const lottieStyles: React.CSSProperties = {
  height: 100,
  width: 100,
};

export default MessageBox;
