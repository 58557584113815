import { Helmet } from 'react-helmet';
import './NotFound.css';
import Lottie, { Options } from 'react-lottie';
import * as animationData from '../../assets/images/dino.json';
import StyledWrapper from '../../components/StyledWrapper/StyledWrapper';

const NotFound: React.FC = () => {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="not-found">
      <Helmet>
        <title>404 - WildMane Engineering</title>
        <meta
          name="description"
          content="The page you are looking for does not exist. Explore our diverse range of services at WildMane Engineering."
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <StyledWrapper
        backgroundColor="#ffffff"
        titleLarge="Page Not Found"
        titleSmall="Sorry, this page does not exist!"
      >
        <div className="lottie-container">
          <Lottie options={defaultOptions} style={lottie} />
        </div>
      </StyledWrapper>
    </div>
  );
};

const lottie: React.CSSProperties = {
  width: '100%',
  maxWidth: 600,
};

export default NotFound;
