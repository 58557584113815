import { create } from 'zustand';

interface MobileState {
  showMobileMenu: boolean;
  toggleMobileMenu: () => void;
  reset: () => void;
}

const useMobileStore = create<MobileState>((set) => ({
  showMobileMenu: false,
  toggleMobileMenu: () =>
    set((state) => ({ showMobileMenu: !state.showMobileMenu })),
  reset: () => set({ showMobileMenu: false }),
}));

export default useMobileStore;
