import './StyledButton.css';

type StyledButtonProps = {
  text: string;
  color?: string;
  backgroundColor?: string;
  onClick: () => void;
  style?: React.CSSProperties;
};

const StyledButton = ({
  color = '#ffffff',
  backgroundColor,
  text,
  style = {},
  onClick,
}: StyledButtonProps) => {
  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor ?? undefined,
        color: color ?? undefined,
        fontWeight: 400,
        ...style,
      }}
      className="styled-button"
    >
      <div className="button-text">{text}</div>
    </div>
  );
};

export default StyledButton;
