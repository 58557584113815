import React from 'react';
import HeroSection from '../../components/HeroSection/HeroSection';
import HeroImage from '../../assets/images/hero.webp';
import './Services.css';
import StyledWrapper from '../../components/StyledWrapper/StyledWrapper';
import SERVICE_IMAGE_1 from '../../assets/images/mobile.webp';
import SERVICE_IMAGE_2 from '../../assets/images/cloud.webp';
import SERVICE_IMAGE_3 from '../../assets/images/embedded.webp';
import SERVICE_IMAGE_4 from '../../assets/images/web.webp';
import SERVICE_IMAGE_5 from '../../assets/images/pcb.webp';
import SERVICE_IMAGE_6 from '../../assets/images/electrical.webp';
import FeaturedService from '../../components/FeaturedService/FeaturedService';
import Timeline, { TimelineEvent } from '../../components/Timeline/Timeline';
import MiniContact from '../../components/MiniContact/MiniContact';
import { Helmet } from 'react-helmet';

type FeaturedServiceItem = {
  title: string;
  description: string;
  image: string;
};

export const featuredServices: FeaturedServiceItem[] = [
  {
    title: 'Mobile Applications',
    description:
      'We create intuitive and high-performance mobile applications tailored to meet your specific business needs, ensuring seamless user experiences on both iOS and Android platforms.',
    image: SERVICE_IMAGE_1,
  },
  {
    title: 'Cloud Applications',
    description:
      'Our team develops scalable and secure cloud applications, leveraging the power of cloud computing to enhance your business operations and ensure data accessibility from anywhere.',
    image: SERVICE_IMAGE_2,
  },
  {
    title: 'Embedded Development',
    description:
      'Specializing in embedded systems, we design and develop custom firmware and software solutions for a variety of hardware platforms, optimizing performance and reliability.',
    image: SERVICE_IMAGE_3,
  },
  {
    title: 'Web Applications',
    description:
      'We build dynamic and responsive web applications that offer exceptional functionality and user engagement, tailored to your business objectives and market demands.',
    image: SERVICE_IMAGE_4,
  },
  {
    title: 'PCB Design',
    description:
      'Our experts provide comprehensive PCB design services, from schematic capture to layout, ensuring efficient and reliable electronic designs for your projects.',
    image: SERVICE_IMAGE_5,
  },
  {
    title: 'Electrical Engineering',
    description:
      'We offer advanced electrical engineering services, including circuit design, system integration, and prototyping, to support the development of cutting-edge electronic solutions.',
    image: SERVICE_IMAGE_6,
  },
];

const Services: React.FC = () => {
  const timelineEvents: TimelineEvent[] = [
    {
      title: 'Consultation and Proposal',
      description:
        'We begin with an initial contact and project discussion, followed by a detailed requirement submission, and end with a proposal review and approval.',
    },
    {
      title: 'Design and Planning',
      description:
        'After contract signing and a project kickoff meeting, we move to concept design and approval.',
    },
    {
      title: 'Development and Testing',
      description:
        'Our process includes product development, regular progress updates, and thorough testing and refinement.',
    },
    {
      title: 'Delivery and Support',
      description:
        'Finally, we ensure final product approval, product delivery with documentation, and provide follow-up support and maintenance options.',
    },
  ];

  return (
    <div className="services">
      <Helmet>
        <title>Services - WildMane Engineering</title>
        <meta
          name="description"
          content="Discover our comprehensive range of software consulting services, including mobile and cloud applications, embedded development, web applications, PCB design, and electrical engineering. We deliver tailored, innovative solutions to meet your unique needs."
        />
        <meta
          name="keywords"
          content="software consulting services, mobile applications, cloud applications, embedded development, web applications, PCB design, electrical engineering, technology solutions"
        />
        <meta name="robots" content="index, nofollow" />
      </Helmet>
      <HeroSection image={HeroImage} title="Our Services" small={true} />
      <StyledWrapper
        backgroundColor="#ffffff"
        titleLarge="First-Class Services"
        style={wrapperStyle}
      >
        <div className="services-description">
          Our services encompass a wide array of solutions to meet your
          technology needs. From mobile and cloud applications to embedded
          development and web applications, we deliver robust and innovative
          software. We also specialize in PCB design and electrical engineering,
          ensuring comprehensive support for your projects. Explore our expert
          services tailored to drive your success.
        </div>
        <div className="featured-services">
          {featuredServices.map(({ title, description, image }, index) => (
            <FeaturedService
              key={title + index}
              title={title}
              description={description}
              image={image}
            />
          ))}
        </div>
      </StyledWrapper>
      <StyledWrapper
        backgroundColor="#D6CEBD"
        titleLarge="Project Process"
        style={wrapperStyle}
      >
        <div className="services-description">
          Our software consulting process is structured into four key phases:
          Consultation and Proposal, Design and Planning, Development and
          Testing, and Delivery and Support. We begin by discussing your needs,
          gathering requirements, and finalizing the proposal. Then, we move
          through contract signing, kickoff meetings, concept design approval,
          product development, regular updates, rigorous testing, final product
          approval, documentation, and ongoing support.
        </div>
        <Timeline events={timelineEvents} />
      </StyledWrapper>
      <StyledWrapper style={wrapperStyle} backgroundColor="#ffffff">
        <MiniContact />
      </StyledWrapper>
    </div>
  );
};

const wrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 35,
};

export default Services;
