// import { ReactComponent as Instagram } from '../../assets/images/instagram.svg';
// import { ReactComponent as Facebook } from '../../assets/images/facebook.svg';
// import { ReactComponent as Linkedin } from '../../assets/images/linkedin.svg';
import { ReactComponent as Mail } from '../../assets/images/mail.svg';
import { ReactComponent as Phone } from '../../assets/images/phone.svg';
import { NavLink } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      {/* <div className="footer-social-media">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.instagram.com"
          aria-label="WildMane Engineering Instagram"
        >
          <Instagram className="footer-media-icon" />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.facebook.com"
          aria-label="WildMane Engineering Facebook"
        >
          <Facebook className="footer-media-icon" />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.linkedin.com/"
          aria-label="WildMane Engineering Linkedin"
        >
          <Linkedin className="footer-media-icon" />
        </a>
      </div> */}
      <div className="footer-contact-us">
        <a
          rel="noopener noreferrer"
          href="mailto:contact-us@wildmane.dev"
          aria-label="WildMane Engineering Email"
        >
          <Mail className="footer-media-icon" />
          <div className="footer-media-text">contact-us@wildmane.dev</div>
        </a>
        <a
          rel="noopener noreferrer"
          href="tel:+5743281323"
          aria-label="WildMane Engineering Facebook"
        >
          <Phone className="footer-media-icon" />
          <div className="footer-media-text">(574) 328-1323</div>
        </a>
      </div>
      <nav className="footer-navigation">
        <NavLink
          to="/"
          className={({ isActive }) =>
            `${isActive ? 'active-footer-item' : ''} footer-navigation-item`
          }
        >
          Home
        </NavLink>
        <NavLink
          to="/services"
          className={({ isActive }) =>
            `${isActive ? 'active-footer-item' : ''} footer-navigation-item`
          }
        >
          Services
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) =>
            `${isActive ? 'active-footer-item' : ''} footer-navigation-item`
          }
        >
          Contact
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) =>
            `${isActive ? 'active-footer-item' : ''} footer-navigation-item`
          }
        >
          About
        </NavLink>
      </nav>
    </footer>
  );
};

export default Footer;
