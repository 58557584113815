import React from 'react';
import HeroSection from '../../components/HeroSection/HeroSection';
import StyledWrapper from '../../components/StyledWrapper/StyledWrapper';
import HeroImage from '../../assets/images/hero.webp';
import ValuesImage from '../../assets/images/values.webp';
import './About.css';
import { Helmet } from 'react-helmet';

type Value = {
  title: string;
  description: string;
};

const About: React.FC = () => {
  const uniqueQualities: string[] = [
    'Customized Solutions',
    'Expert Team',
    'Cutting-Edge Technology',
    'End-to-End Services',
    'Customer-Centric Approach',
    'Proven Track Record',
    'Continuous Improvement',
  ];

  const ourValues: Value[] = [
    {
      title: 'Customer Focus',
      description: `We prioritize understanding and meeting the unique needs of each client, ensuring tailored solutions that drive their success. Our commitment to exceptional service and communication builds long-lasting, trust-based relationships.`,
    },
    {
      title: 'Innovation',
      description: `Embracing the latest technologies and creative problem-solving approaches, we deliver cutting-edge solutions that keep our clients ahead of the curve. We continuously explore new ideas and methods to enhance our services and offerings.`,
    },
    {
      title: 'Integrity',
      description: `Our business practices are rooted in transparency, honesty, and ethical conduct, ensuring trust and reliability in all our interactions. We hold ourselves accountable to the highest standards, fostering a culture of respect and fairness.`,
    },
    {
      title: 'Quality',
      description: `We are dedicated to delivering high-quality work through meticulous attention to detail and a commitment to excellence. Continuous improvement and rigorous testing ensure our solutions meet and exceed client expectations.`,
    },
  ];

  return (
    <div className="about">
      <Helmet>
        <title>About - WildMane Engineering</title>
        <meta
          name="description"
          content="Learn about our dedicated team of software consulting experts committed to delivering innovative and reliable solutions. We focus on customer satisfaction, integrity, and quality to build lasting partnerships and drive success."
        />
        <meta
          name="keywords"
          content="about software consulting, software consulting team, technology experts, innovative solutions, customer satisfaction, software development, company philosophy"
        />
        <meta name="robots" content="index, nofollow" />
      </Helmet>
      <HeroSection image={HeroImage} title="About Us" small={true} />
      <StyledWrapper
        backgroundColor="#ffffff"
        titleLarge="WildMane Engineering"
        style={wrapperStyle}
      >
        <div className="about-description">
          We are a dedicated team of software consulting experts committed to
          delivering innovative and reliable solutions tailored to your unique
          needs. With a focus on customer satisfaction and excellence, we
          provide comprehensive services that span mobile and cloud
          applications, embedded development, web applications, PCB design, and
          electrical engineering. Our commitment to integrity, innovation, and
          quality ensures that we build lasting partnerships and drive mutual
          success.
        </div>
      </StyledWrapper>
      <StyledWrapper backgroundColor="#d6cebd" style={wrapperStyle}>
        <div className="about-more">
          <div className="about-philosophy">
            <div className="about-philosophy-text">
              <div className="about-title">Our Philosophy</div>
              <div className="about-philosophy-description">
                Our philosophy is rooted in a commitment to excellence,
                innovation, and integrity. We believe in delivering tailored
                solutions that not only meet but exceed our clients’
                expectations. By fostering a culture of continuous improvement
                and ethical practices, we build lasting partnerships that drive
                mutual success.
              </div>
            </div>
            <div
              style={{ backgroundImage: `url(${ValuesImage})` }}
              className="about-philosophy-image"
            />
          </div>
          <div className="about-qualities">
            <div className="about-title">Unique Qualities</div>
            <div className="about-qualities-divider" />
            <div className="unique-qualities">
              {uniqueQualities.map((item, index) => {
                return (
                  <div key={item + index} className="unique-quality">
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </StyledWrapper>
      <StyledWrapper
        backgroundColor="#ffffff"
        titleLarge="Our Values"
        style={wrapperStyle}
      >
        <div className="about-values">
          {ourValues.map(({ title, description }, index) => {
            return (
              <div key={title + index} className="value-item">
                <div className="value-title">{title}</div>
                <div className="value-description">{description}</div>
              </div>
            );
          })}
        </div>
      </StyledWrapper>
    </div>
  );
};

const wrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 35,
};

export default About;
