import { ReactNode } from 'react';
import './StyledWrapper.css';

type StyledWrapperProps = {
  backgroundColor: string;
  children?: ReactNode;
  titleLarge?: string;
  titleSmall?: string;
  style?: React.CSSProperties;
  horizontalPadding?: boolean;
};

const StyledWrapper = ({
  children,
  backgroundColor,
  titleLarge,
  titleSmall,
  style,
  horizontalPadding = true,
}: StyledWrapperProps) => {
  return (
    <div style={{ backgroundColor }} className="styled-wrapper">
      <div
        className="styled-wrapper-container"
        style={!horizontalPadding ? { padding: '35px 0' } : undefined}
      >
        {titleLarge || titleSmall ? (
          <div className="styled-wrapper-titles">
            {titleSmall && (
              <div className="styled-wrapper-title-small">{titleSmall}</div>
            )}
            {titleLarge && (
              <h2 className="styled-wrapper-title-large">{titleLarge}</h2>
            )}
          </div>
        ) : null}
        <div style={style}>{children}</div>
      </div>
    </div>
  );
};

export default StyledWrapper;
