import { NavLink } from 'react-router-dom';
import './NavBar.css';
import { ReactComponent as SvgLogo } from '../../assets/images/logo.svg';
import { ReactComponent as Menu } from '../../assets/images/menu.svg';
import useMobileStore from '../../stores/MobileStore';

const NavBar = () => {
  const { toggleMobileMenu } = useMobileStore.getState();
  const showMobileMenu = useMobileStore((state) => state.showMobileMenu);

  return (
    <nav className="nav-bar">
      <div className="nav-container">
        <NavLink to="/">
          <div className="nav-left">
            <SvgLogo className="nav-logo" />
            <div className="nav-company-name">
              WildMane
              <br />
              <span className="nav-engineering">Engineering</span>
            </div>
          </div>
        </NavLink>
        <div className="hamburger-menu" onClick={toggleMobileMenu}>
          <Menu />
        </div>
        <ul className="nav-list">
          <li className="nav-list-item">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? 'nav-list-item-active nav-desktop-item'
                  : 'nav-desktop-item'
              }
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li className="nav-list-item">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? 'nav-list-item-active nav-desktop-item'
                  : 'nav-desktop-item'
              }
              to="/services"
            >
              Services
            </NavLink>
          </li>
          <li className="nav-list-item">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? 'nav-list-item-active nav-desktop-item'
                  : 'nav-desktop-item'
              }
              to="/contact"
            >
              Contact
            </NavLink>
          </li>
          <li className="nav-list-item">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? 'nav-list-item-active nav-desktop-item'
                  : 'nav-desktop-item'
              }
              to="/about"
            >
              About
            </NavLink>
          </li>
        </ul>
        {showMobileMenu && (
          <div className="mobile-backdrop" onClick={toggleMobileMenu}>
            <ul className="mobile-nav-list">
              <li className="mobile-nav-list-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? 'nav-list-item-active mobile-nav-logo'
                      : 'mobile-nav-logo'
                  }
                  to="/"
                >
                  <SvgLogo className="nav-logo" />
                  <div className="nav-company-name">
                    WildMane
                    <br />
                    <span className="nav-engineering">Engineering</span>
                  </div>
                </NavLink>
              </li>
              <li className="mobile-nav-list-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'nav-list-item-active' : undefined
                  }
                  to="/"
                >
                  Home
                </NavLink>
              </li>
              <li className="mobile-nav-list-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'nav-list-item-active' : undefined
                  }
                  to="/services"
                >
                  Services
                </NavLink>
              </li>
              <li className="mobile-nav-list-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'nav-list-item-active' : undefined
                  }
                  to="/contact"
                >
                  Contact
                </NavLink>
              </li>
              <li className="mobile-nav-list-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'nav-list-item-active' : undefined
                  }
                  to="/about"
                >
                  About
                </NavLink>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
