import './FeaturedService.css';

type FeaturedServiceProps = {
  title: string;
  description: string;
  image: string;
};

const FeaturedService = ({
  title,
  description,
  image,
}: FeaturedServiceProps) => {
  return (
    <div className="featured-service">
      <div
        className="featured-service-image"
        style={{ backgroundImage: `url(${image})` }}
      />
      <div className="featured-service-info">
        <div className="featured-service-title">{title}</div>
        <div className="info-divider" />
        <div className="featured-service-description">{description}</div>
      </div>
    </div>
  );
};

export default FeaturedService;
