import './AchievementBar.css';

type Achievement = {
  statistic: string;
  name: string;
};

const achievements: Achievement[] = [
  {
    name: 'Project Success Rate',
    statistic: '100%',
  },
  {
    name: 'Years of Experience',
    statistic: '30+',
  },
  {
    name: 'Client Satisfaction Rate',
    statistic: '100%',
  },
  {
    name: 'Projects Completed',
    statistic: '50+',
  },
];

const AchievementBar = () => {
  return (
    <div className="achievement-bar">
      <div className="achievement-bar-container">
        {achievements.map(({ statistic, name }, index) => {
          return (
            <div className="achievement-item" key={name + index}>
              <div className="achievement-statistic">{statistic}</div>
              <div className="achievement-name">{name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AchievementBar;
