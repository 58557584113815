import './Timeline.css';

export type TimelineEvent = {
  title: string;
  description: string;
};

type TimelineProps = {
  events: TimelineEvent[];
};

const Timeline = ({ events }: TimelineProps) => {
  return (
    <div className="timeline">
      <div className="line" />
      {events.map(({ title, description }, index) => {
        const step = index + 1;
        const odd = step % 2 !== 0;
        return (
          <div
            key={title + index}
            className={`timeline-event${!odd ? ' timeline-event-even' : ''}`}
          >
            <div className={`timeline-info${odd ? ' timeline-info-odd' : ''}`}>
              <div className="timeline-title-section">
                <div className="timeline-title-number">{step}</div>
                <div className="timeline-title">{title}</div>
              </div>
              <div
                className={`timeline-description${odd ? ' timeline-description-odd' : ''}`}
              >
                {description}
              </div>
            </div>
            <div className="timeline-dot" />
            <div className="timeline-spacer" />
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
