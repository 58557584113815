import React from 'react';
import './Contact.css';
import HeroSection from '../../components/HeroSection/HeroSection';
import HeroImage from '../../assets/images/hero.webp';
import StyledWrapper from '../../components/StyledWrapper/StyledWrapper';
import MessageBox from '../../components/MessageBox/MessageBox';
import { Helmet } from 'react-helmet';

const Contact: React.FC = () => {
  return (
    <div className="contact">
      <Helmet>
        <title>Contact - WildMane Engineering</title>
        <meta
          name="description"
          content="Get in touch with our team of software consulting professionals for tailored solutions and exceptional service. Reach out today to discuss your project needs and learn how we can help drive your success."
        />
        <meta
          name="keywords"
          content="contact software consulting, get in touch, software consulting services, technology solutions, project discussion, customer support, business inquiries"
        />
        <meta name="robots" content="index, nofollow" />
      </Helmet>
      <HeroSection image={HeroImage} title="Contact Us" small={true} />
      <StyledWrapper style={wrapperStyle} backgroundColor="#ffffff">
        <MessageBox />
      </StyledWrapper>
    </div>
  );
};

const wrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 35,
};

export default Contact;
