import StyledButton from '../StyledButton/StyledButton';
import Phone from '../../assets/images/phone.webp';
import Lottie, { Options } from 'react-lottie';
import * as animationData from '../../assets/images/phone.json';
import './MiniContact.css';
import { useNavigate } from 'react-router-dom';

const MiniContact = () => {
  const navigate = useNavigate();

  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="mini-contact">
      <img alt="Wired Telephone" src={Phone} className="mini-contact-image" />
      <div className="mini-contact-lottie">
        <Lottie style={lottieStyles} options={defaultOptions} />
      </div>
      <div className="mini-contact-info">
        <div className="mini-contact-text">Did anything interest you?</div>
        <StyledButton onClick={() => navigate('/contact')} text="Contact Us" />
      </div>
    </div>
  );
};

const lottieStyles: React.CSSProperties = {
  height: 100,
  width: 100,
};

export default MiniContact;
