import './HeroSection.css';
import { ReactComponent as WaveImage } from '../../assets/images/wave.svg';
import StyledButton from '../StyledButton/StyledButton';

type HeroSectionProps = {
  image: string;
  title: string;
  description?: string;
  buttonText?: string;
  small?: boolean;
  buttonClick?: () => void;
};

const HeroSection = ({
  image,
  title,
  description,
  buttonText,
  small = false,
  buttonClick,
}: HeroSectionProps) => {
  return (
    <div className="hero-section">
      <div
        className="hero-container"
        style={
          small
            ? {
                height: 200,
              }
            : undefined
        }
      >
        <img
          alt="Hero Visual of Technology and the Wilderness"
          src={image}
          className="hero-image"
          style={
            small
              ? {
                  height: 200,
                }
              : undefined
          }
        />
        <div className="hero-text-container">
          <h1 className="hero-text-title">{title}</h1>
          {description && (
            <div className="hero-text-description">{description}</div>
          )}
          {buttonText && buttonClick ? (
            <StyledButton text={buttonText} onClick={buttonClick} />
          ) : null}
        </div>
        <WaveImage className="hero-wave" />
      </div>
    </div>
  );
};

export default HeroSection;
